<div class="detail-view">
  <div class="page-header pb-3">
    <h1 class="page-title">Invite User</h1>
  </div>

  <form class="form grid-12" (ngSubmit)="onSubmit(inviteUserForm)" #inviteUserForm="ngForm">
    <div class="g-col-12">
      <esa-material-ff
        name="FirstName"
        placeholder="First Name"
        [editMode]="true"
        [required]="true"
        label="First Name"
        type="text"
        [(ngModel)]="model.FirstName">
      </esa-material-ff>

      <esa-material-ff
        name="LastName"
        placeholder="Last Name"
        [editMode]="true"
        [required]="true"
        label="Last Name"
        type="text"
        [(ngModel)]="model.LastName">
      </esa-material-ff>

      <esa-material-ff
        name="Email"
        placeholder="Email"
        [editMode]="true"
        [required]="true"
        label="Email"
        type="text"
        [(ngModel)]="model.Email">
      </esa-material-ff>

      <esa-material-ff
        name="RoleID"
        [editMode]="true"
        [required]="true"
        label="Role"
        type="dropdown"
        [options]="roles$ | async"
        optionLabel="DisplayName"
        optionValue="RoleID"
        [(ngModel)]="model.RoleID">
      </esa-material-ff>
    </div>

    <div class="required-note g-col-8">
      <i class="fas fa-bolt"></i> Required field
    </div>

    <div class="g-col-12 actions-bar" style="margin-top: 1rem">
      <esa-material-button
        type="primary"
        label="Save"
        icon="save"
        [tooltip]="!inviteUserForm.form.valid ? 'You are missing requried fields.' : ''"
        (click)="onSubmit(inviteUserForm)"
        [disabled]="!inviteUserForm.form.valid || isLoadingSubmit"
        style="margin-right: 1rem">
      </esa-material-button>
      <esa-material-button type="clear" label="Cancel" icon="cancel" (click)="cancel()"> </esa-material-button>
    </div>
  </form>
</div>