import {
    Component,
    OnInit,
    OnDestroy,
    Input,
    Output,
    EventEmitter,
} from "@angular/core";
import { CustomPageDto } from "../../generated/model/custom-page-dto";
import { CustomPageService } from "../../generated/api/custom-page.service";
import { UserDto } from "../../generated/model/user-dto";
import { Subscription } from "rxjs";

@Component({
    selector: "header-nav",
    templateUrl: "./header-nav.component.html",
    styleUrls: ["./header-nav.component.scss"],
})
export class HeaderNavComponent implements OnInit, OnDestroy {
    @Input() currentUser: UserDto;
    @Input() environment: string;
    @Input() userBeingImpersonated: boolean;
    @Input() userCanManageDashboard: boolean;
    @Input() userCanViewProjects: boolean;

    @Output() loginClicked = new EventEmitter<any>();
    @Output() logOutClicked = new EventEmitter<any>();
    @Output() editProfileClicked = new EventEmitter<any>();

    customPagesSubscription: Subscription;

    windowWidth: number;
    viewPages: CustomPageDto[] = [];
    learnMorePages: CustomPageDto[] = [];

    constructor(private customPageService: CustomPageService) {}

    ngOnInit() {
        if (this.currentUser) {
               this.customPagesSubscription = this.customPageService.customPagesWithRolesGet().subscribe(customPagesWithRoles => {
                this.viewPages = customPagesWithRoles.filter(x => x.MenuItem.Name == "View");
                this.learnMorePages = customPagesWithRoles.filter(x => x.MenuItem.Name == "LearnMore");
            });
        }
    }

    ngOnDestroy() {
        this.customPagesSubscription?.unsubscribe();
    }

    login(): void {
        this.loginClicked.emit();
    }

    logout(): void {
        this.logOutClicked.emit();
    }

    editProfile(): void {
        this.editProfileClicked.emit();
    }

}
