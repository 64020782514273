<header-nav
    [currentUser]="currentUser"
    (loginClicked)="login()"
    (logOutClicked)="logOut()"
    (editProfileClicked)="editProfile()"
    [environment]="environment"
    [userBeingImpersonated]="userBeingImpersonated"
    [userCanManageDashboard]="userCanManageDashboard"
    [userCanViewProjects]="userCanViewProjects"
></header-nav>

<main class="site">
    <div class="site-wrapper">
        <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
        <router-outlet *ngIf="!isIframe"></router-outlet>
    </div>
</main>

<footer-nav
    [currentUser]="currentUser"
    (loginClicked)="login()"
    (logOutClicked)="logOut()"
></footer-nav>
