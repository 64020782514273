import { Component, OnDestroy, OnInit, ViewContainerRef } from "@angular/core";
import { environment } from "../environments/environment";
import {
    Router,
    RouteConfigLoadStart,
    RouteConfigLoadEnd,
    NavigationEnd,
} from "@angular/router";
import { BusyService } from "./shared/services";
import { Title } from "@angular/platform-browser";
import { AuthenticationService } from "./services/authentication.service";
import { UserDto } from "./shared/generated/model/user-dto";
import { Subscription } from "rxjs";
import { PermissionEnum } from "./shared/generated/enum/permission-enum";
import { RightsEnum } from "./shared/models/enums/rights.enum";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
    currentUser: UserDto;

    isIframe = false;
    userBeingImpersonated: boolean;
    userCanManageDashboard: boolean;
    userCanViewProjects: boolean;
    environment: string;

    routeSubscription: Subscription;
    currentUserSub: Subscription;

    constructor(
        private router: Router,
        private busyService: BusyService,
        private titleService: Title,
        public viewRef: ViewContainerRef,
        private authenticationService: AuthenticationService
    ) {}

    ngOnInit(): void {
        this.isIframe = window !== window.parent && !window.opener;

        this.currentUserSub = this.authenticationService
            .getCurrentUser()
            .subscribe((result) => {
                this.currentUser = result;
                this.userBeingImpersonated =
                    this.authenticationService.isCurrentUserBeingImpersonated(
                        this.currentUser
                    );
                this.userCanViewProjects =
                    this.authenticationService.hasPermission(
                        this.currentUser,
                        PermissionEnum.ProjectRights,
                        RightsEnum.Read
                    );
                this.userCanManageDashboard = this.hasManageMenu();
            });

        this.router.events.subscribe((event: any) => {
            if (event instanceof RouteConfigLoadStart) {
                // lazy loaded route started
                this.busyService.setBusy(true);
            } else if (event instanceof RouteConfigLoadEnd) {
                // lazy loaded route ended
                this.busyService.setBusy(false);
            } else if (event instanceof NavigationEnd) {
                window.scrollTo(0, 0);
            }
        });

        this.titleService.setTitle(`${environment.platformLongName}`);

        if (environment.dev) {
            this.environment = "dev";
        }

        if (environment.staging) {
            this.environment = "staging";
        }

        if (environment.production) {
            this.environment = "production";
        }
    }

    ngOnDestroy(): void {
        this.currentUserSub?.unsubscribe();
    }

    login(): void {
        this.authenticationService.login();
    }

    logOut(): void {
        this.authenticationService.logout();
    }

    editProfile(): void {
        this.authenticationService.editProfile();
    }

    hasManageMenu(): boolean {
        return this.canViewUsers() || this.canViewFieldDefinitions();
    }

    canViewUsers(): boolean {
        return this.authenticationService.hasPermission(
            this.currentUser,
            PermissionEnum.UserRights,
            RightsEnum.Read
        );
    }

    canViewFieldDefinitions(): boolean {
        return this.authenticationService.hasPermission(
            this.currentUser,
            PermissionEnum.FieldDefinitionRights,
            RightsEnum.Read
        );
    }
}
