<div class="detail-view">
    <div class="page-header pb-3">
        <h1 class="page-title">New Project</h1>
        <div class="g-col-6" style="display: flex; flex-direction: column; justify-content: center">
            <p>
                This page allows you to create a new project.
            </p>
        </div>
    </div>

    <shasta-project-form
        #createNewProjectForm
        [project]="project"
        [editMode]="editMode"
        (formSubmitted)="save($event)"
        (cancelEditModeChange)="cancelEdit()"></shasta-project-form>
</div>
