import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CurrentProjectService } from 'src/app/services/current-project.service';
import { EditViewEventService } from 'src/app/services/edit-view-event.service';
import { ProjectDto } from 'src/app/shared/generated/model/project-dto';
import { ProjectUpsertDto } from 'src/app/shared/generated/model/project-upsert-dto';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { ProjectFormComponent } from '../../shared/project-form/project-form.component';

@Component({
  selector: 'shasta-project-detail-summary',
  templateUrl: './project-detail-summary.component.html',
  styleUrls: ['./project-detail-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectDetailSummaryComponent implements OnInit, OnDestroy {
  @ViewChild("updateProjectForm", { read: ProjectFormComponent }) form: ProjectFormComponent;

  public project$: Observable<any>;
  
  public project: ProjectDto;
  public currentUser: UserDto;
  public editMode = false;
  public projectUpsertDto: ProjectUpsertDto;

  user: Subscription;

  constructor(
      private currentProjectService: CurrentProjectService,
      private authenticationService: AuthenticationService,
      private editViewEventService: EditViewEventService,
      private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
      this.user = this.authenticationService.getCurrentUser().subscribe((result) => {
          this.currentUser = result;
          this.cdr.markForCheck();
      });

      this.project$ = this.currentProjectService.getCurrentProject().pipe(
          map((data) => {
              this.project = data;
              this.projectUpsertDto = this.currentProjectService.createProjectDto(this.project);
              this.cdr.markForCheck();
              return data;
          })
      );
  }

  ngOnDestroy(): void {
      this.user.unsubscribe();
  }

  refreshProject(result: any) {
      this.editMode = false;
      this.currentProjectService.setCurrentProject(result);
      this.editViewEventService.editButtonClicked.next(this.editMode);
      this.cdr.detectChanges();
  }

  save(result) {
      this.refreshProject(result);
  }

  canEdit(): boolean {
      return this.currentProjectService.canEditCurrentProject(this?.currentUser, this.project) && !this.editMode;
  }

  enableEditMode() {
      this.editMode = true;
      this.editViewEventService.editButtonClicked.next(this.editMode);
      this.cdr.markForCheck();
  }

  cancelEdit() {
      this.editMode = false;
      this.editViewEventService.editButtonClicked.next(this.editMode);
      this.cdr.markForCheck();
  }

  canExit() {
      if (this.editMode) {
          return JSON.stringify(this.projectUpsertDto) === JSON.stringify(this.form.projectUpsertDto);
      } else {
          return true;
      }
  }
}
