import { Component, OnInit } from "@angular/core";

@Component({
    selector: "shasta-dashboard-manage",
    templateUrl: "./dashboard-manage.component.html",
    styleUrls: ["./dashboard-manage.component.scss"],
})
export class DashboardManageComponent implements OnInit {
    hideText: boolean = false;

    constructor() {}

    ngOnInit(): void {}

    onActivate(e: any) {
        if (e) {
            this.hideText = true;
        }
    }

    onDeactivate(e: any) {
        if (e) {
            this.hideText = false;
        }
    }
}
