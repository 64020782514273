<form class="form grid-12" #projectForm="ngForm">
    <div class="g-col-6">
        <esa-material-ff 
            name="Name" 
            [editMode]="editMode" 
            [required]="true" 
            type="text" 
            [(ngModel)]="projectUpsertDto.Name" 
            [customLabel]="projectNameCustomLabel">
        </esa-material-ff>
        <ng-template #projectNameCustomLabel>
            <shasta-custom-form-label label="Name" fieldDefinitionType="ProjectName"></shasta-custom-form-label>
        </ng-template>
    </div>

    <div class="g-col-6">
        <esa-material-ff
            name="ProjectDate"
            [editMode]="editMode"
            type="date"
            [(ngModel)]="projectUpsertDto.ProjectDate"
            [customLabel]="ProjectDateCustomLabel">
        </esa-material-ff>
        <ng-template #ProjectDateCustomLabel>
            <shasta-custom-form-label label="Date" fieldDefinitionType="ProjectDate"></shasta-custom-form-label>
        </ng-template>
    </div>

    <div class="g-col-12">
        <div class="kv-pair" [ngClass]="editMode ? '' : 'form-spacing'">
            <shasta-custom-form-label label="Description" fieldDefinitionType="ProjectDescription"></shasta-custom-form-label>
            <div class="value" *ngIf="editMode">
                <div class="grid-12">
                    <div class="g-col-12">
                        <tinymce-editor #tinyMceEditor name="Description" [(content)]="projectUpsertDto.Description"></tinymce-editor>
                    </div>
                </div>
            </div>
            <div
                [innerHTML]="projectUpsertDto.Description === null ? '-' : (projectUpsertDto.Description | bypassHtml)"
                class="value-display"
                *ngIf="!editMode"></div>
        </div>
    </div>

    <div class="g-col-12 actions-bar" *ngIf="editMode" style="margin-top: 1rem">
        <esa-material-button
            style="margin-right: 1rem"
            type="primary"
            label="Save"
            icon="save"
            [tooltip]="!projectForm.form.valid ? 'You are missing required fields.' : ''"
            (click)="saveForm(form)"
            [disabled]="!projectForm.form.valid">
        </esa-material-button>

        <esa-material-button type="clear" label="Cancel" icon="cancel" (click)="cancelEditMode()"> </esa-material-button>
    </div>
</form>
