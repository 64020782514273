<div class="detail-view">
    <div class="table-responsive" style="height: 500px">
        <ag-grid-angular
            #projectFileGrid
            style="width: 100%; height: 100%"
            class="ag-theme-balham"
            [rowData]="rowData"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [suppressMenuHide]="true"
            (gridReady)="onProjectFileGridReady($event)"
            [components]="gridComponents">
        </ag-grid-angular>
    </div>

    <shasta-file-uploader 
        [fileRoute]="fileRoute"
        (hasFilesToUpload)="hasFilesToUpload($event)"
        (fileUploadStarted)="fileUploadStarted($event)" 
        (fileUploadSuccess)="fileUploadSuccess($event)" 
        *ngIf="fileRoute && canUploadProjectFile(currentUser)">
    </shasta-file-uploader>
</div>
