<header class="site-header">
    <div *ngIf="environment !== 'production'" class="qa-warning">
        <span class="fa fa-warning"></span>
        {{ environment === 'staging' ? "QA Site: Testing Environment!" : "Development Site: Testing Environment!" }}
    </div>

    <nav class="site-nav">
        <a routerLink="/" routerLinkActive="active" class="site-logo">
            <img src="assets/main/logos/shasta_logo.png" alt="Shasta" />
        </a>

        <button class="hamburger-btn" [dropdownToggle]="mobileMenuToggle" type="button" aria-controls="hamburger-btn" aria-expanded="false" data-cy="mainMenu">
            <span class="sr-only">Open main menu</span>
            <svg aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path>
            </svg>
        </button>

        <div class="site-nav__wrapper" #mobileMenuToggle>
            <a routerLink="/" routerLinkActive="active" class="site-logo-mobile">
                <img src="assets/main/logos/shasta_logo.png" alt="Shasta" />
            </a>

            <ul class="site-nav__links site-nav__main">
                <li class="nav-item dropdown" routerLinkActive="active" *ngIf="viewPages.length > 0">
                    <a href="javascript:void(0);" [dropdownToggle]="viewToggle" class="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        View
                    </a>

                    <div #viewToggle class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a *ngFor="let viewPage of viewPages" routerLink="/custom-pages/{{ viewPage.CustomPageVanityUrl }}" class="dropdown-item">
                            {{ viewPage.DisplayName }}
                        </a>
                    </div>
                </li>

                <li class="nav-item dropdown" routerLinkActive="active" *ngIf="learnMorePages.length > 0">
                    <a href="javascript:void(0);" [dropdownToggle]="learnMoreToggle" class="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Learn More
                    </a>

                    <div #learnMoreToggle class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a *ngFor="let learnMorePage of learnMorePages" routerLink="/custom-pages/{{ learnMorePage.CustomPageVanityUrl }}" class="dropdown-item">
                            {{ learnMorePage.DisplayName }}
                        </a>
                    </div>
                </li>

                <li class="nav-item" *ngIf="userCanManageDashboard">
                    <a routerLink="/manage" class="nav-link" role="button" routerLinkActive="active">
                        Manage
                    </a>
                </li>

                <li class="nav-item" *ngIf="userCanViewProjects">
                    <a routerLink="/projects" class="nav-link" role="button" routerLinkActive="active">
                        Projects
                    </a>
                </li>
            </ul>

            <ul class="site-nav__links site-nav__utilities">
                <ng-container *ngIf="currentUser else unauthenticated">
                    <li class="nav-item">
                        <a href="javascript:void(0);" [dropdownToggle]="helpToggle" class="nav-link dropdown-toggle" aria-haspopup="true" aria-expanded="false">
                            <span class="username" data-cy="userName">{{ userBeingImpersonated ? 'Impersonating' : 'Welcome'}} {{ currentUser?.LoginName }}</span>
                        </a>

                        <div #helpToggle class="dropdown-menu dropdown-menu-right">
                            <a routerLink="/help" class="dropdown-item">
                                Get Help
                            </a>
                            <a href="javascript:void(0);" (click)="editProfile()" class="dropdown-item">Edit Profile</a>
                            <a href="javascript:void(0);" (click)="logout()" class="dropdown-item">{{ userBeingImpersonated ? 'Stop Impersonating' : 'Sign Out'}}</a>
                        </div>
                    </li>
                </ng-container>
                <ng-template #unauthenticated>
                    <li class="nav-item" routerLinkActive="active">
                    <a href="javascript:void(0);" (click)="login()" routerLinkActive="active" class="nav-link" data-cy="signIn">Sign In</a>
                </li>
                </ng-template>
            </ul>
        </div>
    </nav>
</header>