<div class="list-view">
    <div class="page-header">
        <div class="actions-bar flex-between">
            <h1 class="page-title">Custom Pages</h1>
            <esa-material-button
                *ngIf="canCreate"
                type="primary"
                icon="add"
                label="Custom Page"
                aria-label="Add Custom Page"
                (click)="addPage()"
            ></esa-material-button>
        </div>

        <div class="copy copy-2">
            <custom-rich-text
                [customRichTextTypeID]="richTextTypeID"
                [userCanEdit]="canEdit"
                editorId="customPageText"
            ></custom-rich-text>
        </div>
    </div>

    <div class="filter-btn-group align-right mb-3">
        <shasta-clear-grid-filters-button
            class="mr-2"
            [grid]="pageGrid"
        ></shasta-clear-grid-filters-button>
    </div>

    <div class="table-responsive" style="height: 500px">
        <ag-grid-angular
            #pageGrid
            style="width: 100%; height: 100%"
            class="ag-theme-balham"
            [rowData]="rowData"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [suppressMenuHide]="true"
            (firstDataRendered)="onFirstDataRendered($event)"
            [components]="gridComponents"
            (gridReady)="onGridReady($event)"
        >
        </ag-grid-angular>
    </div>
</div>
